import { Fragment } from 'react'

import HeadTag from 'next/head'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { getWalledGarden } from '@/redux/cookies/selectors'
import { FACEBOOK_APP_ID, getSiteBaseUrl } from '@helpers/constants'
import { generateImageUrl } from '@helpers/get-cloudflare-img-url'
import ROUTES from '@helpers/routes'
import useSystemTheme from '@microcomponents/use-system-theme'

import { bool, InferProps, string } from 'prop-types'

const defaultSiteName = 'Eaze | Cannabis Delivery'
export const defaultTitle = 'Weed Delivery | Buy THC and CBD Edibles, Vapes, and more on Eaze'
const defaultDescription =
  'Get the best THC and CBD products delivered same-day with Eaze. Shop flower, vapes, edibles, and joints from legal marijuana brands. Free, fast weed delivery for first-time customers.'

const baseUrl = getSiteBaseUrl()
const shareImgEaze = `${baseUrl}/static/images/OpenGraph_share.jpg`
const defaultImgEaze = `${baseUrl}/static/images/OpenGraph.jpeg`
const telnyxImg = `${baseUrl}/static/images/OpenGraph_telnyx.jpg`

export type HeadProps = InferProps<typeof Head.propTypes>

function Head({
  title = defaultTitle,
  siteName = defaultSiteName,
  description = defaultDescription,
  isWalledOff = false,
  image = defaultImgEaze,
  ogDescription,
  ogImage,
  ogTitle,
  ogType = 'product',
  twitterDescription,
  twitterImage,
  twitterTitle,
  path = '',
  pathname = '',
  promoCode = '',

}: HeadProps) {
  const router = useRouter()
  const queryPromoCode = router?.query?.promoCode
  const hideCannabis = useSelector(getWalledGarden) || isWalledOff

  if (hideCannabis) {
    image = telnyxImg
    title = 'Eaze | Calculated Delivery'
    siteName = 'Eaze | Calculated Delivery'
    description = null
  // We need to show a different opengraph image for /share, but since /share redirects to /signup with a query param, we need to check for that instead.
  } else if ((pathname === ROUTES.SIGNUP && queryPromoCode) || promoCode) {
    image = shareImgEaze
  }
  const systemTheme = useSystemTheme()
  const useDarkTheme = systemTheme === 'dark'

  return (
    <HeadTag>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="google-site-verification" content="r7RqdZlWZl52TLQT-bGsz9-9AdFTrkIzYM0x5zUlmi4" />
      <meta name="viewport" content="initial-scale=1.0, maximum-scale=1, width=device-width" />
      {/* iOS attempts to detect phone numbers, email addresses, and other data in text content and convert them into links, leading to hydration mismatches. */}
      {/* https://nextjs.org/docs/messages/react-hydration-error */}
      <meta name="format-detection" content="telephone=no, date=no, email=no, address=no" />
      <meta name="name" content={title} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content="eaze" />

      {description && <meta name="description" content={description} />}

      {useDarkTheme ? (
        <Fragment>
          <link rel="icon" type="img/png" sizes="16x16" href={`${baseUrl}/static/favicons/dark-mode-16.png`} />
          <link rel="icon" type="img/png" sizes="32x32" href={`${baseUrl}/static/favicons/dark-mode-32.png`} />
        </Fragment>
      ) : (
        <Fragment>
          <link rel="icon" type="img/png" sizes="16x16" href={`${baseUrl}/static/favicons/light-mode-16.png`} />
          <link rel="icon" type="img/png" sizes="32x32" href={`${baseUrl}/static/favicons/light-mode-32.png`} />
        </Fragment>
      )}
      <link
        rel="icon"
        sizes="192x192"
        href={generateImageUrl(`${baseUrl}/static/favicons/android-chrome-192x192.png`, { width: 192 })}
      />
      <link
        rel="icon"
        sizes="512x512"
        href={generateImageUrl(`${baseUrl}/static/favicons/android-chrome-512x512.png`, { width: 512 })}
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href={generateImageUrl(`${baseUrl}/static/favicons/apple-touch-icon.png`, { width: 167 })}
      />
      {useDarkTheme ? (
        <link rel="icon" type="image/x-icon" href="/static/favicons/dark-mode-16.png" />
      ) : (
        <link rel="icon" type="image/x-icon" href="/static/favicons/light-mode-16.png" />
      )}
      {/* Hardcoding eaze.com here so that if there are test clusters indexed we still get the correct link attribution */}
      <link rel="canonical" href={`https://www.eaze.com${path}`} key="canonical" />
      <meta property="og:title" content={ogTitle || title} />

      {description && <meta property="og:description" content={ogDescription || description} />}
      <meta property="og:image" content={ogImage || image} />
      <meta name="twitter:title" content={twitterTitle || title} />
      {description && <meta name="twitter:description" content={twitterDescription || description} />}
      <meta name="twitter:image" content={twitterImage || image} />
      <meta property="og:url" content={baseUrl + path} />
      <meta property="og:image:height" content="375" />
      <meta property="og:image:width" content="475" />
      <meta property="og:type" content={ogType} />
      <meta property="og:site_name" content={siteName} />
      <meta property="fb:app_id" content={FACEBOOK_APP_ID} />
      <meta name="twitter:card" content="product" />
      <meta name="twitter:site" content="@eaze" />
      <meta name="twitter:creator" content="?" />
    </HeadTag>
  )
}

export default Head

Head.propTypes = {
  description: string,
  image: string,
  isWalledOff: bool,
  ogDescription: string,
  ogImage: string,
  ogTitle: string,
  ogType: string,
  path: string,
  pathname: string,
  promoCode: string,
  siteName: string,
  title: string,
  twitterDescription: string,
  twitterImage: string,
  twitterTitle: string
}
